<template>
    <div id="highCER_page">
        <div class="highCER_in">
            <keep-alive>
            <div class="reportcontent">
                <div class="content_top">
                    <div class="report_title">评估报告</div>
                    <div class="report_compInfo"> 
                        <div class="info_list"><div><span>公司名称：</span>{{CompanyInfo.CompanyName}}</div></div>
                        <div class="info_list"><span>纳税人识别号：</span>{{CompanyInfo.TaxpayerIdentityNumber}}</div>
                        <div class="info_list"><span>报告日期：</span>{{nowTime}}</div>
                    </div>
                </div>
                <div class="content_middle">
                    <div class="content_left" id="content_left">
                    
                        <div class="toReportOfPDF">
                            <!-- <el-button type="primary" @click="dialogTableVisible=true">生成PDF报告</el-button> -->
                        </div>

                        <div class="block">
                            <el-timeline>
                                <el-timeline-item
                                v-for="(activity, index) in ZSCQList"
                                :key="index"
                                :icon="activity.icon"
                                :type="activity.type"
                                :color="activity.color"
                                :size="activity.size"
                                :timestamp="activity.type == 'success'? '已申报并完成办理':'计划应在 '+activity.timestamp+' 申报'" 
                                placement="top">
                                
                                    <!-- <transition name="el-fade-in-linear"> -->
                                    <el-card  :shadow="activity.type == 'success'? 'always':'hover'">
                                        <h4>办理项目：
                                            <span v-if="activity.item.Name!=''">{{activity.item.Name}}</span>
                                            <span v-else>{{activity.item.ServiceItemName}}</span> <span v-if="activity.type == 'success'">× {{activity.item.Num}}</span>
                                        </h4>
                                        <p class="ptag" v-if="activity.type=='success'">项目申报日期：
                                            <span v-if="activity.item.RealTimeDisplay!=null">{{activity.item.RealTimeDisplay}}</span>
                                            <span v-else>-</span>
                                        </p>
                                        <!-- <p class="ptag" v-if="activity.type=='info'">已拥有数量：
                                            <span v-if="activity.item.ServiceItemExistNum!=null">{{activity.item.ServiceItemExistNum}}</span>
                                            <span v-else>-</span>
                                        </p> -->
                                        <p class="ptag" v-if="activity.type=='info'">计划申报数量：
                                            <span v-if="activity.item.Num!=null">{{activity.item.Num}}</span>
                                            <span v-else>-</span>
                                        </p>
                                        <p class="will-do" v-show="index == will_do_idx">
                                            <!-- <span class="will-do-right" @click="toProductDetail(index)">去办理>></span> -->
                                            <span class="will-do-right" @click="miniProQrCodeShow = true">去办理>></span>
                                        </p>
                                    </el-card>
                                    <!-- </transition> -->
                                </el-timeline-item>
                            </el-timeline>
                        </div>
                
                    </div>
                    <div class="content_right">
                        <div class="right_div_top">
                            <div class="RT_title">近期新闻</div>
                            <div class="RT_newstList" >
                                <div v-for="(item,index) in newsList" :key="index" class="singlenews" @click="toNewsDetail(item.FLnkID)">
                                    <div class="news_left_img"><img :src="imgUrlFront+item.CoverImgUrl[0]" alt="没有图片资源"></div>
                                    <div class="news_right_info">
                                        <p class="news_right_info_title">{{item.ArticleTitle}}</p>
                                        <p class="news_right_info_summary"><i>{{item.Summary}}</i></p>
                                    </div>
                                </div>
                            </div>
                            <!-- <div>相关产品</div> -->
                        </div>
                    </div>
                </div>
            </div>
            </keep-alive>
        </div>
        <footer-page></footer-page>
        <!-- 展示小程序二维码 -->
        <el-dialog
        title="提示"
        :visible.sync="miniProQrCodeShow"
        width="300px"
        >
        <div class="qrcodeshow">
            <img src="@/assets/images/home/codes.jpg" alt="找不到图片">
        </div>
        <div style="margin-top: 15px;">
            <p>请扫描屏幕中二维码打开微信小程序办理</p>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="miniProQrCodeShow = false">完 成</el-button>
        </span>
        </el-dialog>
        <!-- 用于保存pdf -->
        <el-dialog
        :visible.sync="dialogTableVisible"
        width="592px"
        >   
            <div class="reportBox" id="reportBox">
                <div class="companyInfo">
                    <h2>{{CompanyInfo.CompanyName}}评估报告</h2>
                </div>
                <div class="companyinfo-tin">
                    <span>纳税人识别号：{{CompanyInfo.TaxpayerIdentityNumber}}</span>  
                </div>
                <el-table :data="activities">
                    <el-table-column property="timestamp" label="计划办理日期" width="150"></el-table-column>
                    <el-table-column property="itemname" label="项目名称" ></el-table-column>
                    <el-table-column property="item.Num" label="数量" width="50"></el-table-column>
                    <el-table-column property="status" label="办理状态" width="100">
                        <template slot-scope="scope">
                            <div :style="scope.row.type=='success'?'color: green;':'color:red'">{{scope.row.status}}</div>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="bottom-info"> 
                    <div class="report-bottom-date">报告日期：{{nowTime.split(' ')[0]}}</div>
                    <div class="report-bottom-name">评估方：证企通</div>
                </div>
            </div>
            <template #footer>
                <span class="dialog-footer">
                <el-button @click="dialogTableVisible = false">取 消</el-button>
                <el-button type="primary" @click="ExportSavePdf('reportBox',htmlTitle)">导出PDF</el-button>
                <!-- <el-button type="primary" @click="getPdf('reportBox',htmlTitle)">导出PDF</el-button> -->
                </span>
            </template>
        </el-dialog>
    </div>
</template>
<script>
    import footerPage from '@/components/common/footer/footerPage.vue'
    export default {
        name:'highCEResult',
        data(){
            return{
                ZSCQList:[],//用于动画展示的列表
                activities: [
                    // {
                    // content: '软件著作权',
                    // timestamp: '2018-04-12 20:46',
                    // size: 'large',
                    // type: 'success ',
                    // icon: 'el-icon-check',
                    // item:{}
                    // },
                ],   //评估结果返回列表信息
                CompanyInfo:'',//上个页面传入的公司基本信息
                nowTime:'', //当前时间
                htmlTitle:'证企通评估报告',//打印pdf的报告标题
                newsList:[],//新闻列表
                // productList:[],//
                will_do_idx:'',//即将要办理的一个知识产权项目索引
                dialogTableVisible: false, //是否展示转化打印的pdf
                
                miniProQrCodeShow: false, //是否展示小程序二维码
            }
        },
        computed:{
            userinfo(){
                return this.$store.state.userinfo
            },
            //图片前缀
            imgUrlFront() {
                return window.sessionStorage.getItem("apiImgUrl");
            },
        },
        components:{
            footerPage,  
        },
        created(){
            this.init()
        },
        mounted(){
        },

        methods:{
            init(){
                let willdoflag = true
                this.CompanyInfo = JSON.parse(this.$route.query.CompanyInfo)      
                //调用评估接口
                this.$axios('/API/AdvanceAssess',{
                    Version:'',
                    CompanyName:this.CompanyInfo.CompanyName,
                    TaxpayerIdentityNumber:this.CompanyInfo.TaxpayerIdentityNumber,
                    ArtificialPersonName:this.CompanyInfo.ArtificialPersonName,
                    Regdate:this.CompanyInfo.CreateDate,
                    RegAddressName:this.CompanyInfo.RegAddress,
                    BusinessScope:this.CompanyInfo.BusinessScope,
                    BusinessLicenseUrl:this.$route.query.YYZZimgUrl,
                    ZSCQParams:this.$route.query.arrString,
                    ExistNum:this.$route.query.ZSCQNum,
                    JZToken:this.userinfo.TokenCode,
                    UserGuid:this.userinfo.UserGuid,
                }).then( res => {
                    if(res.status == 200){
                        let flag = false//用于判断是否已办理过改证书标记
                        let today_year = new Date().getFullYear()
                        let today_month = new Date().getMonth()+1
                        let today_date = new Date().getDate()
                        let t_hour = new Date().getHours()
                        let t_minutes = new Date().getMinutes()
                        let t_second = new Date().getSeconds()
                        this.nowTime = today_year + '-' +(
                            today_month < 10 ? '0'+ today_month : today_month
                        ) + '-' + (
                            today_date < 10 ? '0'+ today_date : today_date
                        ) + ' ' + (
                            t_hour < 10 ? '0' + t_hour : t_hour
                        ) + ':' + (
                            t_minutes < 10 ? '0' + t_minutes : t_minutes
                        ) + ':' + (
                            t_second < 10 ? '0' + t_second : t_second
                        )
                        
                        res.data.forEach( (item,idx) => {
                            let year = parseInt(item.ShouldTimeDisplay.split(' ')[0].split('-')[0])
                            let month = parseInt(item.ShouldTimeDisplay.split(' ')[0].split('-')[1])
                            let date = parseInt(item.ShouldTimeDisplay.split(' ')[0].split('-')[2])
                            if(year < today_year){
                                flag = true
                            }else if(year == today_date){
                                if(month < today_month){
                                    flag = true
                                }else if(month == today_month){
                                    if(date < today_date){
                                        flag = true
                                    }else{
                                        flag = false
                                    }
                                }else{
                                    flag = false
                                }
                            }else{
                                flag = false
                            }
                            let defalutObj = {
                                timestamp:item.ShouldTimeDisplay.split(' ')[0],
                                size:'large',
                                type:flag ? 'success': 'info',
                                icon:flag ? 'el-icon-check':'el-icon-close',
                                item:item,

                                //用于生成pdf列表所需的数据
                                status: flag ? '已办理': '未办理',
                                itemname:item.Name != '' ? item.Name : item.ServiceItemName 

                            }
                            if(willdoflag && !flag){
                                this.will_do_idx = idx
                                willdoflag = false
                            }
                            this.activities.push(defalutObj)
                            
                        })
                        this.activities.forEach( (item,idx) => {
                            setTimeout(() => {
                                this.ZSCQList.push(item)
                            },2000*idx)
                        })
                        

                        
                    }
                    
                })
                //请求新闻接口
                this.$axios('/API/Get_Article',{
                    Version: '',
                    Status: 3,
                    IsShowIndex: 1,
                    ArticleTypeID: '',
                    PageIndex: 1,
                    PageSize: 4
                }).then( res => {
                    res.data.forEach((item) => {
                        item.CoverImgUrl = decodeURIComponent(item.CoverImgUrl).split(",",2);
                        item.Tags = item.Tags.split(",");
                        item.ArticleTitle = decodeURIComponent(item.ArticleTitle);
                        item.Summary = decodeURIComponent(item.Summary)
                        this.newsList.push(item);
                    })
                    // console.log(this.newsList)
                })
               
            },
            toNewsDetail(flnkid){
                this.$router.push({
                    name: "newsdetail",
                    query: {
                    newsflnkid: flnkid,
                    },
                });
            },
            //打开小程序码
            openMiniPro(){},

            //点击去办理先不进行商品跳转直接打开小程序码
            toProductDetail(index){
                this.$router.push({
                    name: "productdetail",
                    query: {
                        flnkid : this.activities[index].item.ServiceItemGuid
                    }
                })
            }
        },

    }
</script>

<style lang="scss" scoped>
    @import './highCEResult.scss';
    .qrcodeshow{
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        img{
            width: 160px;
            height: 160px;
        }
    }

</style>
